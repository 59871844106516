import React, { Component } from 'react';
import { Menu, X } from 'react-feather';
import Logo from '../../images/mad-logo.svg';

class Header extends Component {
  constructor(props) {
    super(props);
    this._toggle = this._toggle.bind(this);
    this.state = { expandMenu: false };
  }

  _toggle() {
    this.setState({ expandMenu: !this.state.expandMenu });
  }

  render() {
    return (
      <nav>
        <div className="logo"><img src={Logo} alt="logo" height="24px" /></div>
        <div className="menu_toggle" onClick={this._toggle}>
          {
            this.state.expandMenu ? <X /> : <Menu />
          }
        </div>
        <ul className={this.state.expandMenu ? 'showMenu' : ''}>
          <li><a href="#slide-2">Making VR fun</a></li>
          <li><a href="#slide-3">Your own style</a></li>
          <li><a href="#slide-4">A smart world</a></li>
          <li><a href="#slide-5">Where to next?</a></li>
        </ul>
      </nav>
    )
  }
}

export default Header;