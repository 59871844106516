import React from 'react';
import Header from './components/header';
import { ChevronsDown } from 'react-feather';
// import Group from './images/MadMash1.PNG';
// import Group2 from './images/MadMash3.PNG';
import Group3 from './images/MadMash4.PNG';
import Group8 from './images/MadMash8.PNG';
import Trailer from './vids/madmash-2.mp4';
import Fundme from './vids/Fund-MADMASH.mp4';

function App() {
  return (
    <div className="wrapper">
      <Header />

      <a name="slide-1" href="/" aria-hidden="true" className="anchor">MadMash Trailer</a>
      <div className="slide first">
        <div className="coverbg">
          <video autoPlay loop muted
            height="100%" poster={Group3}>
            <source id='mp4' src={Trailer} type='video/mp4' />
            <p>Your user agent does not support the HTML5 Video element.</p>
          </video>
        </div>
        <div className="slide-inner">
          <h1 className="hero">MADMASH<sup>&trade;</sup></h1>
          <h2>A truly immersive virtual world where the possiblities are endless. <br /> Go Berserk!</h2>
          {/* <p>A never ending battle royal with you at the heart.</p> */}
          <button><ChevronsDown /></button>
        </div>
      </div>

      <a name="slide-2" href="/" aria-hidden="true" className="anchor">Making VR fun</a>
      <div className="slide">
        {/* <div className="coverbg">
          <img src={Group2} width="100%" alt="Play system, MadMash Games" />
        </div> */}
        <div className="slide-inner cols">
          <div className="slide-col">
            <h1>PLAY! PLAY! PLAY!</h1>
            <h2>Making hours of play, fun and easy in VR.</h2>
            <p>MADMASH &trade; was designed from the ground up to be easily playable for long periods of time in VR. The apprach to every part of the experience was guided by 100s of trials resulting in an interaction and play system that felt natural, intuitive and fun.</p>
          </div>

          <div className="slide-col">
            <div className="slide-img">
              <video controls
                width="100%" poster={Group3}>
                <source id='mp4' src={Trailer} type='video/mp4' />
                <p>Your user agent does not support the HTML5 Video element.</p>
              </video></div>
            <div className="caption">Immerse yourself in a virtual adventure world.</div>
          </div>
        </div>
      </div>

      <a name="slide-3" href="/" aria-hidden="true" className="anchor">Your own style of play</a>
      <div className="slide">
        <div className="slide-inner cols">
          <div className="slide-col">
            <h1>Your own style of play</h1>
            <h2>We provide the world, you decide the rest</h2>
            <p>We wanted to break out of the somewhat restrictive play in most VR games and instead take full advantage of the immersive 3D world. Rather than being prescriptive we want you to decide on what fits you best, wether it be moving or choice of weapons. The posibilities are endless.</p>
          </div>

          <div className="slide-col">
            <div className="slide-img"><img src={Group8} alt="Own style of play, MadMash Games" /></div>
            <div className="caption">Our weapon select system was specially deisgned to be intutive with natural movement in mind.</div>
          </div>
        </div>
      </div>

      <a name="slide-4" href="/" aria-hidden="true" className="anchor">A truly smart world</a>
      <div className="slide">
        <div className="slide-inner cols">
          <div className="slide-col">
            <h1>A TRULY SMART WORLD</h1>
            <h2>A new procedurally generated immersive experience every single time.</h2>
            <p>Playing the same level at times can get boring. Every level of Madmash is procedurally generated, which means new pathways, enimies and objectives every single time. Every asset is open sourced which means mods form the get go. We want you to bring your own characters and accessories into Madmash.</p>
          </div>

          <div className="slide-col">
            <div className="slide-img"><img src={Group3} alt="A truly smart world, MadMash Games" /></div>
            <div className="caption">A uniqe layout everytime you play a level. It never gets boring.</div>
          </div>
        </div>
      </div>

      <a name="slide-5" href="/" aria-hidden="true" className="anchor">Where to next?</a>
      <div className="slide">
        <div className="slide-inner cols">
          <div className="slide-col">
            <h1>A truly global world and a play system!</h1>
            <h2>Help fund us</h2>
            <p>We're at the early stages of evaluations and funding to go into full production mode. If you would like to know more, watch game play videos or  contact us on <a href="https://www.instagram.com/madmashgames/" target="_blank" rel="noopener noreferrer">the MadMash Games Intagram.</a></p>
          </div>



          <div className="slide-col">
            <div className="slide-img">
              <video controls
                width="100%" poster={Group3}>
                <source id='mp4' src={Fundme} type='video/mp4' />
                <p>Your user agent does not support the HTML5 Video element.</p>
              </video></div>
            <div className="caption">Help fund MadMash become a reality.</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
